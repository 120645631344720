import { withRouter } from 'react-router-dom'
import {
  compose, branch, renderComponent, withProps
} from 'recompose'

import HotspotDesktop from '../../components/hotspot/hotspot-desktop'
import HotspotMobile from '../../components/hotspot/hotspot-mobile'
import HotspotGuest from '../../components/hotspot/hotspot-guest'

import { isLargerThan1024 } from '../../lib/screen'
import { getLocation } from './util'
import withIsAuthenticated from '../../hoc/is-authenticated'
import content from '../../components/hotspot/hotspot-guest/content'
import { withScreenWidthWatching } from '../../hoc/with-screen-width-watching'
import withConfigCacheOnly from '../../hoc/with-config'

export default compose(
  withScreenWidthWatching,
  withConfigCacheOnly,
  withIsAuthenticated,
  withRouter,
  branch(
    // check if non-guest or no hardcoded in hotspot-guest content (eg: pathname 'rental' or 'max')
    ({ isAuthenticated, location }) => isAuthenticated || !content[getLocation(location.pathname || 'home')],
    branch(
      isLargerThan1024,
      renderComponent(HotspotDesktop),
      renderComponent(HotspotMobile)
    ),
    compose(
      withProps(({ location, appConfig }) => {
        return {
          pageContent:
            content[getLocation(location.pathname || 'home')],
          appConfig
        }
      }),
      renderComponent(HotspotGuest)
    )
  )
)()
