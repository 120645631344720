import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import styles from './fader.css'
import NavDots from './nav-dots'
import { checkIsAuthenticated } from '../../../lib/auth'

function HotspotFader({
  children,
  className,
  clickToSwitch,
  showItem,
  isSquare,
  rentalInfo
}) {
  const mapState = useSelector(state => state)
  const { session } = mapState
  const isAuthenticated = session && checkIsAuthenticated(mapState)
  const location = useLocation()
  const isChangeBg = !isAuthenticated && location.pathname === '/'
  const updatedChildren = React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      index,
      showItem,
      className: classnames(className, styles.slide, {
        [styles.show]: index === showItem
      })
    })
  })
  return (
    <div
      className={classnames(styles.container, {
        [styles.square]: isSquare
      })}
    >
      {updatedChildren}
      {!isChangeBg && children.length > 1 && (
        <NavDots
          onClick={clickToSwitch}
          count={children.length}
          current={showItem}
          rentalInfo={rentalInfo}
        />
      )}
    </div>
  )
}

HotspotFader.propTypes = {
  className: PropTypes.string,
  showItem: PropTypes.number,
  isSquare: PropTypes.bool,
  clickToSwitch: PropTypes.func.isRequired,
  rentalInfo: PropTypes.arrayOf(PropTypes.bool).isRequired
}

HotspotFader.defaultProps = {
  className: '',
  showItem: 0,
  isSquare: false
}

export default HotspotFader
